export { onBeforeRoute }

import type { OnBeforeRouteSync } from 'vike/types'
import {redirect} from "vike/abort";

const onBeforeRoute: OnBeforeRouteSync = (pageContext): ReturnType<OnBeforeRouteSync> => {
    console.log("IN ON BEFORE ROUTE")

    const urls = pageContext._pageRoutes.map((item: any) => {
        return item.routeString
    })

    if (!urls.includes(pageContext.urlPathname)) {
        if (!["/error", "/error/"].includes(pageContext.urlPathname)) {
            // throw redirect("/error")

            return {
                pageContext: {
                    is404: true // Only return the property you're adding or changing
                }
            }
        }
    }

    return {
        pageContext: {
            urlOriginal: pageContext.urlOriginal
        }
    }
}